import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <img
            src="/logo.png"
            alt="Company Logo"
            className="logo"
            onClick={handleLogoClick}
          />
        </div>
        <div className="footer-column">
          <nav className="footer-nav">
            <a href="/how-it-works" className="link">
              How It Works
            </a>
            <a href="/about" className="link">
              About
            </a>
            <a href="#" className="link">
              Contact
            </a>
            <a href="/Register-Your-Store" className="link">
              Register your store!
            </a>
          </nav>
        </div>
        <div className="footer-column">
          <h4>Contact</h4>
          <p>Email: ntemba7224@gmail.com</p>
          <p>Phone: +260-964593742</p>
        </div>
        <div className="footer-column center">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="#"><FaFacebook /></a>
            <a href="#"><FaTwitter /></a>
            <a href="https://www.instagram.com/ntembaz/"><FaInstagram /></a>
            <a href="#"><FaLinkedinIn /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Ntembaz. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
