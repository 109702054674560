import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaInstagram, FaFacebook, FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import "./Dashboard.css";

const Dashboard = () => {
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://ntembaapi.onrender.com/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      });
      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.access_token);
        setMessage("Login successful!");
        checkUserType(data.access_token); // Proceed to check user type
      } else {
        setMessage("Login failed. Please check your username and password.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    }
  };

  const checkUserType = async (token) => {
    try {
      const response = await fetch("https://ntembaapi.onrender.com/user/details", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.type === 1) {
          setIsLoggedIn(true);
          fetchStores();
        } else {
          setMessage("Access denied. Only Administrators can view this dashboard.");
        }
      } else {
        throw new Error("User details not found.");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchStores = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("https://ntembaapi.onrender.com/register/", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStores(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch stores", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      checkUserType(token);
    } else {
      setLoading(false);
    }
  }, []);

  const handleStoreClick = (store) => {
    setSelectedStore(store);
  };

  const closePopup = () => {
    setSelectedStore(null);
  };

  if (!isLoggedIn) {
    return (
      <div className="login-container">
      <img src="/logo.png" alt="Company Logo" className="login-logo" />
        <div className="login-form">
        <div className="input-container">
          <FontAwesomeIcon icon={faUser} className="input-icon" />
          <input
            type="text"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            required
          />
        </div>
        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
        </div>
        <button onClick={handleLogin}>Login</button>
        {loading && <div className="loading-spinner"></div>}
        </div>
        {message && <p>{message}</p>}
      </div>

    );
  }

  return (
    <div className="dashboard">
      <h2>Store Registry Dashboard</h2>
      {loading ? (
        <div className="loading">Loading stores...</div>
      ) : (
        <div className="store-list">
          {stores.map((store) => (
            <div
              key={store.id}
              className="store-card"
              onClick={() => handleStoreClick(store)}
            >
              <h3>{store.store_name}</h3>
              <p>Owner: {store.store_owner_first_name} {store.store_owner_last_name}</p>
              <p>Category: {store.category}</p>
              <p className="location">
                <FaMapMarkerAlt /> {store.store_location || "Online only"}
              </p>
            </div>
          ))}
        </div>
      )}
      {selectedStore && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h2>{selectedStore.store_name}</h2>
            <p><strong>Owner:</strong> {selectedStore.store_owner_first_name} {selectedStore.store_owner_last_name}</p>
            <p><strong>Contact:</strong> {selectedStore.store_owner_number}</p>
            <p><strong>Email:</strong> {selectedStore.store_owner_email}</p>
            <p><strong>Category:</strong> {selectedStore.category}</p>
            <p><strong>Location:</strong> {selectedStore.store_location || "Online only"}</p>
            <p><strong>Description:</strong> {selectedStore.description}</p>
            <div className="social-links">
              {selectedStore.store_instagram && (
                <a href={`https://instagram.com/${selectedStore.store_instagram}`} target="_blank" rel="noopener noreferrer">
                  <FaInstagram /> Instagram
                </a>
              )}
              {selectedStore.store_facebook && (
                <a href={`https://facebook.com/${selectedStore.store_facebook}`} target="_blank" rel="noopener noreferrer">
                  <FaFacebook /> Facebook
                </a>
              )}
            </div>
            <button className="close-button" onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
