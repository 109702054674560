import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SplashScreen from "./SplashScreen";
import Signup from "./Signup";
import Login from "./Login";
import About from "./About";
import Item from "./Item";
import CreateAStore from "./CreateAStore";
import Works from "./Works";
import Contact from "./Contact";
import Dashboard from "./Dashboard";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/Register-Your-Store" element={<CreateAStore />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/how-it-works" element={<Works />} />
        <Route path="/item/:itemId" element={<Item />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
