import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Signup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faUserTag, faLock } from "@fortawesome/free-solid-svg-icons";

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    full_name: "",
    password: "",
    type: 0,
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(""); // Clear previous message
    try {
      const response = await axios.post("https://ntembaapi.onrender.com/user", formData);

      if (response.status === 200) {
        setMessage("Signup successful!");
        // Redirect to login screen after successful signup
        navigate("/login");
      } else {
        setMessage("Signup failed. Please check your details.");
      }
    } catch (error) {
      setMessage("Error: " + (error.response?.data?.detail || "Signup failed"));
    }
    setLoading(false);
  };

  return (
    <div className="signup-container">
      <img src="/logo.png" alt="Company Logo" className="signup-logo" />
      <form onSubmit={handleSubmit} className="signup-form">
        <div className="input-container">
          <FontAwesomeIcon icon={faUser} className="input-icon" />
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Username"
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faUserTag} className="input-icon" />
          <input
            type="text"
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
            placeholder="Full Name"
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
        </div>

        <button type="submit">Sign Up</button>
        {loading && <div className="loading-spinner"></div>}
      </form>
      {message && <p className="message">{message}</p>}
      <p className="login-link">
        Already have an account?{" "}
        <span onClick={() => navigate("/login")} className="login-link-text">
          Login
        </span>
      </p>
    </div>
  );
};

export default Signup;
