import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaHeart,
  FaShoppingCart,
  FaSearch,
  FaBars,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true); //chamge to false when login functionality is enabled
  const [showDropdown, setShowDropdown] = useState(false);
  const [username, setUsername] = useState("");
  const [menuClass, setMenuClass] = useState("mobile-menu-hidden");
  const navigate = useNavigate();

  /* 
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      fetch("https://ntembaapi.onrender.com/user/details", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("User details not found.");
          }
        })
        .then((data) => {
          setUsername(data.username);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
          setIsLoggedIn(false);
        });
    }
  }, []);

  */


  const toggleMobileMenu = () => {
    if (isMobileMenuOpen) {
      setMenuClass("mobile-menu-close");
      setTimeout(() => {
        setIsMobileMenuOpen(false);
        setMenuClass("mobile-menu-hidden");
      }, 300); // Match the animation duration
    } else {
      setIsMobileMenuOpen(true);
      setMenuClass("mobile-menu-open");
    }
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
    setIsLoggedIn(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <header className="header">
      {!isLoggedIn && (
        <div className="top-bar">
          <div className="auth-links">
            <a className="auth-link" href="/login">
              Log In
            </a>
            <a className="auth-link" href="/signup">
              Sign Up
            </a>
          </div>
        </div>
      )}
      <div className="main-header">
        <img
          src="/logo.png"
          alt="Company Logo"
          className="logo"
          onClick={handleLogoClick}
        />
        <nav className={`nav-links ${menuClass}`}>
          <a href="/how-it-works" className="link">
            How It Works
          </a>
          <a href="/about" className="link">
            About Us
          </a>
          <a href="/contact" className="link">
            Contact
          </a>
          <a href="/Register-Your-Store" className="link">
            Register your store!
          </a>
          {isMobileMenuOpen && isLoggedIn && (
            <div className="mobile-user">
              <span className="mobile-username">{username}</span>
              {/*
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
              */}
            </div>
          )}
        </nav>
        <div className="header-icons">
          <FaSearch className="icon search-icon" />
          <FaHeart className="icon" />
          <FaShoppingCart className="icon cart-icon" />
          <FaUser className="icon" />
          {isLoggedIn && (
            <span className="username" onClick={toggleDropdown}>
              {username}
              {showDropdown && (
                <div className="dropdown-menu">
                  {
                  <button className="logout-button" onClick={handleLogout}>
                    Logout
                  </button>
                }
                </div>
              )}
            </span>
          )}
        </div>
        <FaBars className="mobile-menu-icon" onClick={toggleMobileMenu} />
      </div>
    </header>
  );
};

export default Header;
