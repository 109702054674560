import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import './Item.css';

const Item = () => {
  const { itemId } = useParams();
  const [item, setItem] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    fetch(`http://localhost:8000/item/get_item_by_id/${itemId}`)
      .then(response => response.json())
      .then(data => {
        // Ensure categories are unique
        const uniqueCategories = [...new Set(data.categories)];
        setItem({ ...data, categories: uniqueCategories });
      })
      .catch(error => console.error('Error fetching item details:', error));
  }, [itemId]);

  const handleAddToCart = () => {
    console.log('Add to cart');
  };

  const handleBuyNow = () => {
    console.log('Buy now');
  };

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  return (
    <div className="item-page">
      <Header /> {/* Include the Header component */}
      {item ? (
        <div className="item-details-container">
          <div className="item-image-container">
            <img src={item.image_url ? `/${item.image_url}` : '/item.png'} alt={item.name} />
          </div>
          <div className="item-details">
            <div className="title-price">
              <h2>{item.name}</h2>
              <p className="price">K{item.price}</p>
            </div>
            <p className='description'>{item.description}</p>
            <div className='categories'>
              {item.categories.map((category, index) => (
                <span
                  key={index}
                  className='category'
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </span>
              ))}
            </div>
            {isLoggedIn ? (
              <div className="item-actions">
                <button className="add-to-cart-button" onClick={handleAddToCart}>Add to Cart</button>
                <button className="buy-now-button" onClick={handleBuyNow}>Buy Now</button>
              </div>
            ) : (
              <button className="login-button" onClick={() => navigate('/login')}>Log In to Buy</button>
            )}
          </div>
        </div>
      ) : (
        <p className='loading'>Loading...</p>
      )}
    </div>
  );
};

export default Item;
