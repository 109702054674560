import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { MdConnectWithoutContact } from "react-icons/md";
import Header from './Header';
import Footer from './Footer';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <Header />
      <div className="contact-container">
        <h2 className="contact-title"><MdConnectWithoutContact />Get in Touch with us!</h2>
        <div className="contact-details">
          <div className="contact-info">
            <h3>Contact Information</h3>
            <p>Email: ntemba7224@gmail.com</p>
            <p>Phone: +260-964593742</p>
          </div>
          <div className="contact-social">
            <h3>Follow Us</h3>
            <div className="contact-social-icons">
              <a href="#" className="contact-social-icon" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="#" className="contact-social-icon" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
              <a href="https://www.instagram.com/ntembaz/" className="contact-social-icon" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="#" className="contact-social-icon" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
