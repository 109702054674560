import React from "react";
import "./Works.css";
import { FaSearch, FaCartPlus, FaCreditCard, FaSmile } from "react-icons/fa";
import Header from "./Header";
import Footer from "./Footer";

const Works = () => {
  return (
    <div className="works">
      <Header />
      <div className="works-container">
        <h2 className="works-title">How It Works</h2>
        <div className="steps">
          <div className="step">
            <FaSearch className="step-icon" />
            <h3>1. Browse Products</h3>
            <p>
              Explore a variety of products available on NtembaZ. Use the search
              bar to find exactly what you're looking for.
            </p>
            <img src="/step1.png" alt="Browse Products" className="step-image" />
          </div>
          <div className="step">
            <FaCartPlus className="step-icon" />
            <h3>2. Add to Cart</h3>
            <p>
              Found something you like? Add it to your cart and continue
              shopping or proceed to checkout.
            </p>
            <img src="/step2.png" alt="Add to Cart" className="step-image-02" />
          </div>
          <div className="step">
            <FaCreditCard className="step-icon" />
            <h3>3. Secure Payment</h3>
            <p>
              Choose your preferred payment method and complete your purchase
              securely with our trusted payment gateway.
            </p>
            <img src="/step3.png" alt="Secure Payment" className="step-image" />
          </div>
          <div className="step">
            <FaSmile className="step-icon" />
            <h3>4. Enjoy Your Purchase</h3>
            <p>
              Relax while your order is processed and delivered to your
              doorstep. Enjoy your purchase from NtembaZ!
            </p>
            <img src="/cartoon.png" alt="Enjoy Your Purchase" className="step-image-04" />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Works;
