import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./About.css";

const About = () => {
  return (
    <div className="about-page">
      <Header /> {/* Include the Header component */}
      <div className="about-content">
        <section className="intro-section">
          {/* 
          <button className="get-started-button"><FaLocationArrow /> Get Started</button>
          <button className="watch-video-button"><FaPlay/> Watch Video</button>
          */}
        </section>
        <section className="about-us-section">
          <h3>About Us</h3>
          <p>
            We are passionate about connecting customers with store owners,
            providing a seamless shopping experience that is both convenient and
            reliable.
          </p>
          <p>
            At NtembaZ, we believe in the power of local businesses and the
            value they bring to our communities. Our platform is designed to
            bridge the gap between buyers and sellers, ensuring that everyone
            has access to a wide range of products at competitive prices.
          </p>
          <p>
            Whether you're shopping from the comfort of your home or running a
            business from a bustling market, NtembaZ is here to support you
            every step of the way.
          </p>
          <p>
            We are committed to excellence, innovation, and customer
            satisfaction. Join us in creating a vibrant and thriving marketplace
            where quality and service are never compromised.
          </p>
        </section>
        <div className="image-container">
          <img src="/cartoon.png" alt="About Ntemba" />
        </div>
      </div>
      <Footer /> {/* Include the Footer component */}
    </div>
  );
};

export default About;
