import React, { useState } from "react";
import Header from "./Header";
import ListItems from "./ListItems";
import { AiTwotoneShop } from "react-icons/ai";
import {
  MdDeliveryDining,
  MdOutlineSpeakerPhone,
  MdHandshake,
} from "react-icons/md";
import "./SplashScreen.css";
import Footer from "./Footer";
import axios from 'axios';

const API_URL = 'https://ntembaapi.onrender.com/'; // Replace with your FastAPI URL
const interval = 30000; // Interval in milliseconds (30 seconds)

function reloadWebsite() {
  axios.get(API_URL)
    .then(response => {
      console.log(`Reloaded at ${new Date().toISOString()}`);
    })
    .catch(error => {
      console.log('Pinged!');
    });
}

setInterval(reloadWebsite, interval);


const SplashScreen = () => {
  const [selectedCategory, setSelectedCategory] = useState("ALL");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="splash-screen">
      <Header />
      <div className="splash-content-01">
        <div className="banner-01">
          <h2>WELCOME TO NTEMBAZ</h2>
          <p>From the Store to your Door, One Delivery at a time.</p>
          <button className="coming-soon-button">Coming Soon</button>
        </div>
        <img
          src="/moped.png"
          alt="Banner Background"
          className="background-image-01"
        />
      </div>
      <div className="splash-content-02">
        <div className="banner-02">
          <h2>ORDER ANYTHING AND EVERYTHING</h2>
          <h2>From ANYWHERE!</h2>
        </div>
        <img
          src="/view.png"
          alt="Banner Background"
          className="background-image-02"
        />
      </div>
      <div className="splash-content-03">
        <div className="banner-03">
          <h2>ALL YOUR FAVORITE ONLINE STORES</h2>
          <h2>IN ONE PLACE</h2>
        </div>
        <img
          src="/icons.png"
          alt="Banner Background"
          className="background-image-03"
        />
      </div>
      {/*
      <div className="product-categories">
        {["ALL", "FEATURED", "NEW", "SPECIALS", "RATED"].map((category) => (
          <button
            key={category}
            className={`category-button ${selectedCategory === category ? "selected" : ""}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </button>
        ))}
      </div>
    */}
      <div className="splash-content-04">
        <div className="banner-04">
          <section className="features-section">
            <div className="feature-box">
              <AiTwotoneShop className="SP-icon" />
              <h3>Discover amazing stores and exclusive deals.</h3>
            </div>
            <div className="feature-box">
              <MdDeliveryDining className="SP-icon" />
              <h3>Get your orders delivered swiftly and safely.</h3>
            </div>
            <div className="feature-box">
              <MdOutlineSpeakerPhone className="SP-icon" />
              <h3>Explore local stores right from your home.</h3>
            </div>
            <div className="feature-box">
              <MdHandshake className="SP-icon" />
              <h3>Connecting communities, one delivery at a time.</h3>
            </div>
          </section>
        </div>
      </div>
      <div>
        {/*<ListItems category={selectedCategory} />  // uncomment to show the items*/}
      </div>
      <Footer />
    </div>
  );
};

export default SplashScreen;
